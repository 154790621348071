@import 'src/styles/colours';
@import "src/styles/fonts";

.home {
  height: 120vh;
  min-height: 80vw;
  width: 100vw;

  background-image: url("../../images/eshoot_ellen_dave-57.jpg");
  background-position: 42% 30%;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .home-spacer {
    height: 12vh;
  }
}

@import '../colours';

.schedule {
  width: 100vw;

  background: $light-blue;

  .schedule-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .schedule-text {
      margin: 5rem 2rem 6rem 2rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        max-width: 70vw;
        text-align: center;
      }
    }

    img {
      width: 100vw;
      height: 100vw;
      object-fit: cover;
    }
  }

  a, a:link, a:visited a:hover {
    color: $greenish-dark-blue;
    text-decoration: none;
  }

  a:active {
    color: $greenish-dark-blue;
    text-decoration: underline;
  }

  @media only screen and (min-width: 800px) {
    .schedule-container {
      flex-direction: row;

      .schedule-text {
        margin: 4rem 4rem 4rem 4rem;
        width: 50vw;

        span {
          max-width: 45vw;
          text-align: center;
        }
      }

      img {
        margin: 3rem 0 3rem 3rem;
        max-height: 88vh;
        max-width: 44vw;
        object-fit: cover;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .schedule-container {
      flex-direction: row;

      img {
        margin-left: 10vw;
      }

      .schedule-text {
        margin-right: 10vw;
      }
    }
  }
}
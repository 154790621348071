@import "src/styles/colours";

.admin {
  min-height: 100vh;
  background: $light-blue;

  display: flex;
  flex-direction: column;

  .admin-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem 2rem 6rem 2rem;

    .admin-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;

      button {
        background-color: white;
      }

      .header-buttons-container{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }

    @media only screen and (min-width: 700px) {
      .admin-header {
        flex-direction: row;
        align-items: flex-end;
      }
    }
  }
}
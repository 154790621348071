.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-start {
  align-items: start;
}

.flex-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-space-between {
  justify-content: space-between;
}
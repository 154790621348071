@import "src/styles/fonts";
@import "src/styles/colours";

.guest-info {
  display: flex;
  flex-direction: column;

  .guest-info-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .guest-info-food {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  input[type=text] {
    @extend %font-default-label;
    font-size: 1rem;
    background-color: $light-grey;
  }

  input[type=radio] {
    min-width: 1rem;
  }
}


@import "colours";

%font-default-label {
  color: $greenish-dark-blue;
  font-family: Montserrat;
  line-height: 150%;
}

.font-tiny-label {
  @extend %font-default-label;
  font-size: 0.675rem;
}

.font-small-label {
  @extend %font-default-label;
  font-size: 0.8rem;
}

.font-regular-label {
  @extend %font-default-label;
  font-size: 1rem;
}

.font-regular-medium-label {
  @extend %font-default-label;
  font-size: 1rem;
  font-weight: 500;
}

.font-medium-label {
  @extend %font-default-label;
  font-size: 1rem;
  font-weight: 600;
}

.font-regular-bold-label {
  @extend %font-default-label;
  font-size: 1rem;
  font-weight: 700;
}

.font-regular-cursive-label {
  @extend %font-default-label;
  font-family: Parisienne;
  font-size: 1.5rem;
}

.font-large-label {
  @extend %font-default-label;
  font-size: 1.2rem;

}

.font-huge-label {
  @extend %font-default-label;
  font-size: 1.7rem;
}

.font-giant-label {
  @extend %font-default-label;
  font-size: 3.5rem;
}

.font-large-cursive-label {
  @extend %font-default-label;
  font-family: Parisienne;
  font-size: 2.5rem;
  line-height: 110%;
}

.font-huge-cursive-label {
  @extend %font-default-label;
  font-family: Parisienne;
  font-size: 2.5rem;
  line-height: 110%;
}

.uppercase {
  text-transform: uppercase;
}

.font-white {
  color: white !important;
}

@media only screen and (min-width: 350px) {
  %font-default-label-350 {
    color: $greenish-dark-blue;
    font-family: Montserrat;
    line-height: 150%;
  }

  .font-large-label {
    @extend %font-default-label-350;
    font-size: 1.5rem;
  }

  .font-huge-label {
    @extend %font-default-label-350;
    font-size: 2.5rem;
  }

  .font-giant-label {
    @extend %font-default-label-350;
    font-size: 3.5rem;
  }

  .font-large-cursive-label {
    @extend %font-default-label-350;
    font-family: Parisienne;
    font-size: 3.5rem;
    line-height: 110%;
  }

  .font-huge-cursive-label {
    @extend %font-default-label-350;
    font-family: Parisienne;
    font-size: 3.7rem;
    line-height: 110%;
  }
}

@media only screen and (min-width: 600px) {
  %font-default-label-600 {
    color: $greenish-dark-blue;
    line-height: 110%;
  }

  .font-giant-label {
    @extend %font-default-label-600;
    font-size: 5rem;
  }

  .font-large-cursive-label {
    @extend %font-default-label-600;
    font-family: Parisienne;
    font-size: 4.5rem;
  }

  .font-huge-cursive-label {
    @extend %font-default-label-600;
    font-family: Parisienne;
    font-size: 6rem;
  }
}
.save-the-date {
  background: $cream-white;
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .save-the-date-image {
    max-height: 90vh;
    max-width: 100vw;
    object-fit: contain;
  }

  .details-label {
    color: $greenish-dark-blue;
    height: 5rem;
    width: 100vw;
    font-family: Montserrat;
    font-size: 0.875rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

@import '../colours';

.details {
  width: 100vw;

  background: $light-blue;

  .details-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .details-text {
      margin: 5rem 1rem 6rem 1rem;

      display: flex;
      flex-direction: column;

      span {
        max-width: 90vw;
      }
    }

    img {
      max-width: 100vw;
      max-height: 150vw;
      object-fit: cover;
    }

    @media only screen and (min-width: 350px) {
      .details-text {
        margin: 5rem 2rem 6rem 2rem;

        display: flex;
        flex-direction: column;

        span {
          max-width: 70vw;
        }
      }
    }
  }

  @media only screen and (min-width: 800px) {
    .details-container {
      flex-direction: row;

      .details-text {
        margin: 4rem 4rem 4rem 4rem;
        width: 50vw;

        span {
          max-width: 35vw;
        }
      }

      img {
        margin: 3rem 3rem 3rem 0;
        max-height: 88vh;
        max-width: 44vw;
        object-fit: cover;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .details-container {
      flex-direction: row;

      .details-text {
        margin-left: 10vw;
      }

      img {
        margin-right: 10vw;
      }
    }
  }
}

@import 'src/styles/colours';
@import "src/styles/fonts";

.join {
  height: 120vh;
  width: 100vw;

  background-image: url("../../images/eshoot_ellen_dave-43.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;

  span {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
  }

  .join-spacer {
    height: 12vh;
  }

  .photo-credit {
    width: 100vw;
    text-align: center;
    background: $opaque-light-blue;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    a {
      color: $greenish-dark-blue;
      margin-left: 0.50rem;
      text-decoration: underline;
    }
  }
}

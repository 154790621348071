@import 'src/styles/colours';

.landing {
  position: relative;

  .landing-content {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .anchor {
    visibility: hidden;
  }

  .e-and-d {
    position: fixed;
    top: 0;
    margin-top: .6rem;
    margin-left: 1rem;
  }

  %default-header-bar {
    transition: all 0.5s ease;
    position: fixed;
    width: 100vw;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
    }
  }

  #header-bar {
    @extend %default-header-bar;
    flex-direction: column;
    visibility: hidden;
    top: 3.1rem;

    span {
      margin: 1rem 0 1rem 0;
    }
  }

  .header-menu {
    @extend %default-header-bar;
    justify-content: flex-end;
    visibility: visible;

    img {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      margin: .8rem 1rem 0.8rem 1rem;
    }
  }

  @media only screen and (min-width: 600px) {
    #header-bar {
      @extend %default-header-bar;
      flex-direction: row;
      justify-content: flex-end;
      visibility: visible !important;
      top: 0;

      span {
        margin: .8rem 2rem 0.8rem 0;
      }
    }

    .header-menu {
      visibility: hidden;
    }

    .header-bar-background-invisible {
      transition: all 0.5s ease;
      background: transparent !important;
    }
  }

  .background-opaque {
    transition: all 0.5s ease;
    background: $opaque-light-blue;
  }

  .header-menu-background-invisible {
    transition: all 0.5s ease;
    background: transparent;
  }

  .header-bar-background-invisible {
    transition: all 0.5s ease;
    background: $opaque-light-blue;
  }

  .show-header-bar {
    transition: all 0.5s ease;
    visibility: visible;
    background: $opaque-light-blue;
  }

  .hide-header-bar {
    transition: all 0.5s ease;
    visibility: hidden;
    background: transparent;
  }

  .e-and-d-visible {
    transition: all 0.5s ease;
    color: $greenish-dark-blue;
  }

  .e-and-d-invisible {
    transition: all 0.5s ease;
    color: transparent;
  }
}


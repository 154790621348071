@import "src/styles/colours";

.rsvp {
  min-height: 100vh;
  background: $light-blue;

  .rsvp-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .rsvp-image {
      position: relative;
      width: 100vw;
      height: 150vw;
      filter: grayscale(100%);

      img {
        position: absolute;
        width: 100vw;
        height: 150vw;
        object-fit: cover;
      }

      .rsvp-image-filter {
        position: absolute;
        width: 100vw;
        height: 150vw;
        background: rgba(black, 0.2);

        display: flex;
        flex-direction: column;
        align-items: center;

        .rsvp-spacer {
          height: 70vw;
        }

        span {
          margin-left: 1rem;
          margin-right: 1rem;
          text-align: center;
        }
      }
    }

    .rsvp-text {
      margin: 1rem 1rem 4rem 1rem;
    }
  }

  @media only screen and (min-width: 350px) {
    .rsvp-container {
      align-items: stretch;

      .rsvp-text {
        margin: 2rem 2rem 4rem 2rem;
      }
    }
  }

  @media only screen and (min-width: 800px) {
    .rsvp-container {
      align-items: center;

      .rsvp-image {
        position: relative;
        width: 100vw;
        height: 100vh;

        img {
          position: absolute;
          width: 100vw;
          height: 100vh;
        }

        .rsvp-image-filter {
          position: absolute;
          width: 100vw;
          height: 100vh;

          .rsvp-spacer {
            height: 45vh;
          }
        }
      }

      .rsvp-text {
        margin: 4rem 4rem 4rem 4rem;
        width: 50vw;
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .rsvp-container {
      align-items: center;

      .rsvp-text {
        width: 40vw;
      }
    }
  }

  .header-bar {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 3.1rem;
    background: $opaque-light-blue;
  }

  .e-and-d {
    position: fixed;
    top: 0;
    margin-top: .6rem;
    margin-left: 1rem;
  }

  button {
    background: #233653;
    color: white;
    border: none;
    box-shadow: transparent;
    padding: 0.2rem 1rem 0.2rem 1rem;
  }
}
.margin-top-03-rem {
  margin-top: 0.3rem;
}

.margin-bottom-05-rem {
  margin-bottom: 0.5rem;
}

.margin-1-rem {
  margin: 1rem;
}

.margin-horizontal-1-rem {
  margin-left: 1rem;
  margin-right: 1rem;
}

.margin-vertical-1-rem {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.margin-top-1-rem {
  margin-top: 1rem;
}

.margin-bottom-1-rem {
  margin-bottom: 1rem;
}

.margin-left-1-rem {
  margin-left: 1rem;
}

.margin-right-1-rem {
  margin-right: 1rem;
}

.margin-2-rem {
  margin: 2rem;
}

.margin-horizontal-2-rem {
  margin-left: 2rem;
  margin-right: 2rem;
}

.margin-vertical-2-rem {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.margin-top-2-rem {
  margin-top: 2rem;
}

.margin-bottom-2-rem {
  margin-bottom: 2rem;
}

.margin-left-2-rem {
  margin-left: 2rem;
}

.margin-right-2-rem {
  margin-right: 2rem;
}

.margin-top-4-rem {
  margin-top: 4rem;
}

.margin-bottom-4-rem {
  margin-bottom: 4rem;
}
@import 'src/styles/colours';
@import "src/styles/fonts";

.success {
  height: 100vh;
  width: 100vw;

  background-image: url("../../images/eshoot_ellen_dave-43.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;

  span {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
  }

  .success-spacer {
    height: 12vh;
  }
}

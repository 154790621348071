@import '../colours';
@import "../fonts";

.faq {
  width: 100vw;

  background: $light-blue;

  display: flex;
  flex-direction: column;

  .faq-content {
    margin: 5rem 2rem 6rem 2rem;

    display: flex;
    flex-direction: column;

    .faq-q {
      @extend %font-default-label;
      font-size: 1rem;
      font-weight: 500;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      letter-spacing: 0;
    }

    .faq-a {
      @extend %font-default-label;
      font-size: 1rem;
      font-weight: 300;
      margin-bottom: 0.5rem;
      letter-spacing: 0;
    }
  }

  img {
    margin-top: 1rem;
    margin-right: 1rem;
    object-fit: contain;
    max-width: 100vw;
  }

}

@media only screen and (min-width: 800px) {
  .faq {
    flex-direction: row;

    .faq-content {
      margin: 8rem 4rem 4rem 4rem;
    }

    img {
      max-width: 70vw;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .faq {
    flex-direction: row;

    .faq-content {
      margin-left: 10vw;
      margin-right: 10vw;
    }

    img {
      max-width: 55vw;
    }
  }
}
